<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-12" v-for="device in devices" :key="device._id">
      <div class="card card-body">
        <div class="row">
          <div class="col-12 col-lg-2 g text-center">
            <img :src="device.image" style="width: 100%; max-width: 200px" />
          </div>
          <div class="col-12 col-lg-6 g">
            <br />
            <h4>
              {{ device.title }}
            </h4>
            <h5 v-if="device.available" class="text-success">
              <i class="fa fa-check"></i> الجهاز متوفر
            </h5>
            <h5 v-if="!device.available" class="text-danger">
              <i class="fa fa-times"></i> الجهاز غير متوفر
            </h5>
            <p v-html="device.details.replaceAll('\n', '<br>')"></p>
          </div>
          <div class="col-12 col-lg-4 g text-center">
            <h1 style="font-family: Arial !important" class="text-primary">
              {{ device.price }} ريال
            </h1>
            <button
              class="btn btn-block btn-relief-success"
              v-if="device.available"
              @click="
                loggedin
                  ? $router.push('/buy/' + device._id)
                  : $router.push('/login?backto=/buy/' + device._id)
              "
            >
              <i class="fa fa-shopping-cart"></i>
              طلب الآن
            </button>
            <a
              target="_blank"
              href="https://tahdir.net/support"
              class="btn btn-block btn-light"
            >
              <i class="fa fa-phone"></i>
              تواصل معنا
            </a>
            <br />
            (السعر يشمل الضريبة والشحن والبرمجة)
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BCardBody,
  BCard,
  BLink,
  BImg,
  BCardText,
} from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    BButton,
    BCardBody,
    BCard,
    BLink,
    BImg,
    BCardText,
  },
  data() {
    return {
      devices: [],
      loading: true,
      loggedin: localStorage.getItem("user") ? true : false,
    };
  },
  created() {
    var g = this;
    $.post(api + "/client/devices/list")
      .then(function (r) {
        if (r.status == 100) {
          g.devices = r.response;
        } else {
          alert("حدث خطأ في الخادم، برجاء التواصل مع الدعم الفني");
        }
        g.loading = false;
      })
      .catch(function () {
        alert("حدث خطأ في الخادم، برجاء التواصل مع الدعم الفني");
        g.loading = false;
      });
  },
};
</script>


<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>